import { connect } from 'react-redux';
import ThankYou from 'components/modules/ThankYouPageNew';
import { ApplicationApi } from 'redux/modules/App/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { getIsLeadCaptureDialogOpen } from '../LeadCapture/api';
import { setLeadCaptureDialog } from '../LeadCapture/actions';

const mapStateToProps = (state) => {
  const isOrganic = ApplicationApi.isOrganicApplication(state);
  const isSales = ApplicationApi.isSalesApplication(state);
  const isPartnerLead = !isOrganic && !isSales;
  const { first } = OrderSummaryApi.getMainAccountHolderName(state);
  return {
    name: first,
    isPartnerLead,
    customerAccountNumber: ApplicationApi.getAccountNumber(state),
    showSmartBanner: !!ApplicationApi.getSmartLink(state),
    leadCaptureDialogOpen: getIsLeadCaptureDialogOpen(state),
  };
};

export default connect(mapStateToProps, { setLeadCaptureDialog })(ThankYou);
