export const PREFIX = '##prefix##';
export const PRICE = 'PRICE';
export const CAP = '##cap##';

// Locked benefits messages
export const BUNDLE_ONE_AND_ENERGY_FIXED_OR_20 =
  'BUNDLE_ONE_AND_ENERGY_FIXED_OR_20';
export const ADD_ENERGY_FIXED_OR_20 = 'ADD_ENERGY_FIXED_OR_20';
export const ADD_ANOTHER_FIXED_OR_20 = 'ADD_ANOTHER_FIXED_OR_20';

export const BUNDLE_TWO_AND_ENERGY_FIXED_OR_50 =
  'BUNDLE_TWO_AND_ENERGY_FIXED_OR_50';
export const ADD_ENERGY_FIXED_OR_50 = 'ADD_ENERGY_FIXED_OR_50';
export const ADD_ONE_FIXED_OR_50 = 'ENERGY_ONE_SERVICE';
export const ANOTHER_AND_ENERGY_FIXED_OR_50 = 'ANOTHER_AND_ENERGY_FIXED_OR_50';
export const ADD_TWO_FIXED_OR_50 = 'ENERGY_TWO_SERVICE';

export const BUNDLE_THREE_AND_ENERGY_EXTRA_50 =
  'BUNDLE_THREE_AND_ENERGY_EXTRA_50';
export const ADD_THREE_EXTRA_50 = 'ADD_THREE_EXTRA_50';
export const ADD_TWO_EXTRA_50 = 'ADD_TWO_EXTRA_50';
export const ADD_ONE_EXTRA_50 = 'ADD_ONE_EXTRA_50';
export const ADD_ENERGY_EXTRA_50 = 'ADD_ENERGY_EXTRA_50';
export const ADD_ONE_AND_ENERGY_EXTRA_50 = 'ADD_ONE_AND_ENERGY_EXTRA_50';
export const ADD_TWO_AND_ENERGY_EXTRA_50 = 'ADD_TWO_AND_ENERGY_EXTRA_50';
export const ADD_TWO_MOBILE_EXTRA_50 = 'ADD_TWO_MOBILE_EXTRA_50';
export const ADD_TWO_MOBILE_CHEAPEST_FIXED = 'ADD_TWO_MOBILE_CHEAPEST_FIXED';

export const TEMPLATED_ENERGY = 'TEMPLATED_ENERGY';
export const EARLY_TERMINATION = 'EARLY_TERMINATION';
export const ADD_TWO_MOBILE_EARLY_TERMINATION =
  'ADD_TWO_MOBILE_EARLY_TERMINATION';

export const TBYB_BROADBAND = 'TBYB_BROADBAND';

export const lockedBenefits = {
  // 20 or fixed
  [BUNDLE_ONE_AND_ENERGY_FIXED_OR_20]:
    'Bundle a service with energy to unlock a fixed energy tariff, or £20 off the government’s price cap with our variable tariff',
  [ADD_ENERGY_FIXED_OR_20]:
    'Add energy to unlock a fixed energy tariff, or £20 off the government’s price cap with our variable tariff',
  [ADD_ANOTHER_FIXED_OR_20]:
    'Add another service to unlock a fixed energy tariff, or £20 off the government’s price cap with our variable tariff',

  // 50 or fixed
  [BUNDLE_TWO_AND_ENERGY_FIXED_OR_50]:
    'Bundle two services with energy to unlock our cheapest fixed energy tariff, or £50 off the government’s price cap with our variable tariff',
  [ADD_ENERGY_FIXED_OR_50]:
    'Add energy to unlock our cheapest fixed energy tariff, or £50 off the government’s price cap with our variable tariff',
  [ADD_ONE_FIXED_OR_50]:
    'Add one more service to unlock our cheapest fixed energy tariff, or £50 off the government’s price cap with our variable tariff',
  [ANOTHER_AND_ENERGY_FIXED_OR_50]:
    'Add energy and another service to unlock our cheapest fixed energy tariff, or £50 off the government’s price cap with our variable tariff',
  [ADD_TWO_FIXED_OR_50]:
    'Add two more services to unlock our cheapest fixed energy tariff, or a variable tariff £50 lower than the government’s price cap',

  // 50 extra
  [BUNDLE_THREE_AND_ENERGY_EXTRA_50]:
    'Bundle three services with energy to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
  [ADD_THREE_EXTRA_50]:
    'Add three more services to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
  [ADD_TWO_EXTRA_50]:
    'Add two more services to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
  [ADD_ONE_EXTRA_50]:
    'Add one more service to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
  [ADD_ENERGY_EXTRA_50]:
    'Add energy to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
  [ADD_ONE_AND_ENERGY_EXTRA_50]:
    'Add energy and one more service to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
  [ADD_TWO_AND_ENERGY_EXTRA_50]:
    'Add energy and two more services to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',

  // Second Mobile
  [ADD_TWO_MOBILE_CHEAPEST_FIXED]:
    "Add two SIMs to unlock our cheapest fixed energy tariff, or £50 off the government's energy price cap with our variable tariff",
  [ADD_TWO_MOBILE_EARLY_TERMINATION]:
    'Add two SIMs to unlock £400 towards early termination fees with your current suppliers',
  [ADD_TWO_MOBILE_EXTRA_50]:
    'Add two SIMs to unlock an extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',

  // rest
  [TEMPLATED_ENERGY]: `${PREFIX} energy tariff for £${PRICE} a month, or £${CAP} off the government's energy price cap with our variable tariff`,
  [EARLY_TERMINATION]:
    'Add one more service to unlock £400 towards early termination fees with your current suppliers',
  [TBYB_BROADBAND]: 'Full Fibre broadband for £0 for 6 months',
};

// Unlocked benefits messages
export const FIXED_ENERGY_OR_20_UNLOCKED = 'FIXED_ENERGY_OR_20_UNLOCKED';
export const FIXED_ENERGY_OR_50_UNLOCKED = 'FIXED_ENERGY_OR_50_UNLOCKED';
export const EARLY_TERMINATION_UNLOCKED = 'EARLY_TERMINATION_UNLOCKED';
export const EXTRA_50_UNLOCKED = 'EXTRA_50_UNLOCKED';

export const unlockedBenefits = {
  [FIXED_ENERGY_OR_50_UNLOCKED]:
    'Our cheapest fixed energy tariff, or £50 off the government’s price cap with our variable tariff',
  [FIXED_ENERGY_OR_20_UNLOCKED]:
    'Fixed energy tariff, or £20 off the government’s price cap with our variable tariff',
  [EARLY_TERMINATION_UNLOCKED]:
    '£400 towards early termination fees with your current suppliers',
  [EXTRA_50_UNLOCKED]:
    'An extra £50 bundle discount, bringing our variable energy price to £100 below the government’s price cap',
};

// Savings tips
export const savingsTips = {
  noneSelected: 'Add two services to unlock your first saving!',
  energyOnly:
    'Add another service to unlock a fixed energy tariff, or £20 off the government’s price cap!',
  energyPlusOne:
    'Add one more service to unlock our cheapest fixed energy tariff, or £50 off the government’s price cap!',
  energyPlusTwo: 'Add one more service to unlock an extra £50 bundle discount!',
  allSelected:
    'You’ve unlocked your maximum savings by adding all four services!',
  allSelectedAvailable:
    'You’ve unlocked your maximum savings by adding all services!',
  oneService:
    'Add energy to unlock a fixed energy tariff, or £20 off the government’s price cap!',
  multipleServices:
    'Add energy to unlock our cheapest fixed energy tariff, or £50 off the government’s price cap!',
};

export const defaultSavingsBenefits = {
  messages: {
    unlocked: [],
    locked: [],
    savingsTip: '',
  },
};
