import React from 'react';
import { useSelector } from 'react-redux';
import ThankYou from 'redux/modules/ThankYouPage/container';
import ThankYouNew from 'pages/ThankYouNew';

import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';

const ThankYouSwitch = () => {
  const isNewPageEnabled = useSelector((state) =>
    FeatureFlagsApi.getFlag(state, FLAGS.THANKYOU_PAGE_NEW)
  );

  return (
    <>
      {isNewPageEnabled && <ThankYouNew />}
      {!isNewPageEnabled && <ThankYou />}
    </>
  );
};

export default ThankYouSwitch;
