import Immutable from 'immutable';
import {
  SET_DETAILS_DROPDOWN_OPEN,
  SET_DETAILS_DROPDOWN_SELECTED,
} from './constants';

const initialState = Immutable.Map();

export default function DetailsDropdownReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DETAILS_DROPDOWN_OPEN:
      return state.mergeDeep({
        [action.id]: {
          open: action.open,
        },
      });

    case SET_DETAILS_DROPDOWN_SELECTED:
      return state.mergeDeep({
        [action.id]: {
          selected: action.selected,
        },
      });

    default:
      return state;
  }
}
