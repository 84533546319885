import { takeLatest, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import { POST_SMART_METER_INTEREST_REQUEST } from './constants';
import postSmartMeterInterest from './service/postSmartMeterInterest';

export function* combinedSagas() {
  yield takeLatest(
    POST_SMART_METER_INTEREST_REQUEST,
    handlePostSmartMeterInterest
  );
}

export function* handlePostSmartMeterInterest() {
  try {
    yield call(postSmartMeterInterest);
    yield put(actions.requestComplete());
  } catch (error) {
    yield put(actions.requestFailed(error));
  }
}
