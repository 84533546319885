import {
  POST_SMART_METER_INTEREST_REQUEST,
  POST_SMART_METER_INTEREST_SUCCESS,
  POST_SMART_METER_INTEREST_FAILURE,
} from './constants';

export const submit = () => ({ type: POST_SMART_METER_INTEREST_REQUEST });
export const requestComplete = () => ({
  type: POST_SMART_METER_INTEREST_SUCCESS,
});
export const requestFailed = (error) => ({
  type: POST_SMART_METER_INTEREST_FAILURE,
  error,
});
