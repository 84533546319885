import {
  SET_DETAILS_DROPDOWN_OPEN,
  SET_DETAILS_DROPDOWN_SELECTED,
} from './constants';

export const setOpen = (id, open) => ({
  type: SET_DETAILS_DROPDOWN_OPEN,
  id,
  open,
});

export const setSelected = (id, selected) => ({
  type: SET_DETAILS_DROPDOWN_SELECTED,
  id,
  selected,
});
