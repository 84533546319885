import {
  CAP,
  PREFIX,
  PRICE,
  lockedBenefits,
  unlockedBenefits,
} from './benefits';

const addWhen = (condition, element) => (condition ? [element] : []);

const buildUnlockedSaving = (savingId) => ({
  id: savingId,
  description: unlockedBenefits[savingId] ?? '',
  enabled: true,
});

const getDescription = (fixedQuotesSummary, isFixedSaverQuote, savingId) => {
  let description = lockedBenefits[savingId];

  if (!fixedQuotesSummary) {
    return description;
  }

  if (isFixedSaverQuote) {
    return description
      .replace(PREFIX, 'Our cheapest fixed')
      .replace(PRICE, fixedQuotesSummary.fixedSaverQuotes.value / 100)
      .replace(CAP, 50);
  }

  return description
    .replace(PREFIX, 'Fixed')
    .replace(PRICE, fixedQuotesSummary.fixedQuotes.value / 100)
    .replace(CAP, 20);
};

const buildLockedSaving = (fixedQuotesSummary) => (
  savingId,
  unlockedBy = [],
  enabled = true,
  isFixedSaverQuote = false
) => ({
  id: savingId,
  description: getDescription(fixedQuotesSummary, isFixedSaverQuote, savingId),
  enabled,
  unlockedBy,
});

export { addWhen, buildUnlockedSaving, buildLockedSaving };
