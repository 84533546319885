/* eslint-disable no-console */

import { BANK_ACCOUNT_DETAILS_ERROR_MESSAGE } from 'app/constants/validation';

/**
 * translateBackendErrorToMessage -
  utility to translate a backend error object to user acceptable error message string
 * @param  {object} error
 * @return {string} message
 */
export function translateBackendErrorToMessage(error) {
  if (!error.response) return error.message; // STD JS Error

  const { status, data } = error.response;

  if (status === 400) {
    if (data.type === 'SchemaViolationError' && console && console.error) {
      console.error('Schema violation error', data.violations);
    }

    if (data.validationDetails) {
      return typeof data.validationDetails === 'string'
        ? data.validationDetails
        : data.validationDetails.message;
    }
  } else if (status === 404 && data.type === 'BankAccountNotFoundError') {
    return BANK_ACCOUNT_DETAILS_ERROR_MESSAGE;
  }

  return data.message || data;
}
