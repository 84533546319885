/**
 * @param {number} serviceCount - number of selected services
 * @param {Object} [lead] - the refferal lead, if available
 * @param {Object} lead.leadProspect - lead prospect information. Mainly the prospect name
 * @param {string} lead.leadProspect.preferredFirstName - lead first name
 */
export const getBoostMessage = (serviceCount, lead) => {
  const benefit = {
    locked: null,
    unlocked: null,
  };

  if (!lead) {
    return benefit;
  }

  const leadFirstName = lead?.leadProspect?.preferredFirstName;

  switch (serviceCount) {
    case 0: {
      benefit.locked = `Add two services to unlock £50 Referral Bonus for you, and £50 for ${leadFirstName ??
        'your friend'}`;
      break;
    }

    case 1: {
      benefit.locked = `Add another service to unlock £50 Referral Bonus for you, and £50 for ${leadFirstName ??
        'your friend'}`;
      break;
    }

    case 2:
    case 3:
    case 4: {
      benefit.unlocked = `£50 Referral Bonus for you, and £50 for ${leadFirstName ??
        'your friend'}`;
      break;
    }

    default:
      return benefit;
  }

  return benefit;
};
