import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
  PRODUCT_MOBILE_2,
} from 'redux/modules/Bundles/constants';

import {
  ADD_ENERGY_FIXED_OR_20,
  ANOTHER_AND_ENERGY_FIXED_OR_50,
  EARLY_TERMINATION,
  ADD_ANOTHER_FIXED_OR_20,
  ADD_ENERGY_EXTRA_50,
  ADD_ENERGY_FIXED_OR_50,
  ADD_ONE_AND_ENERGY_EXTRA_50,
  ADD_ONE_EXTRA_50,
  ADD_ONE_FIXED_OR_50,
  ADD_THREE_EXTRA_50,
  ADD_TWO_AND_ENERGY_EXTRA_50,
  ADD_TWO_EXTRA_50,
  ADD_TWO_FIXED_OR_50,
  BUNDLE_ONE_AND_ENERGY_FIXED_OR_20,
  BUNDLE_THREE_AND_ENERGY_EXTRA_50,
  BUNDLE_TWO_AND_ENERGY_FIXED_OR_50,
  EARLY_TERMINATION_UNLOCKED,
  EXTRA_50_UNLOCKED,
  FIXED_ENERGY_OR_20_UNLOCKED,
  FIXED_ENERGY_OR_50_UNLOCKED,
  TEMPLATED_ENERGY,
  savingsTips,
} from './benefits';
import {
  addWhen,
  buildUnlockedSaving,
  buildLockedSaving as buildLockedSavingUnwrapped,
} from './utils';

export const buildBundleSavings = (fixedQuotesSummary, isPrepayment) => {
  const buildLockedSaving = buildLockedSavingUnwrapped(fixedQuotesSummary);

  return [
    {
      serviceIds: [
        PRODUCT_ENERGY,
        PRODUCT_BROADBAND,
        PRODUCT_MOBILE,
        PRODUCT_MOBILE_2,
        PRODUCT_INSURANCE,
      ],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
          buildUnlockedSaving(EXTRA_50_UNLOCKED),
        ],
        locked: [],
        savingsTip: savingsTips.allSelected,
      },
    },
    {
      serviceIds: [
        PRODUCT_BROADBAND,
        PRODUCT_MOBILE,
        PRODUCT_MOBILE_2,
        PRODUCT_INSURANCE,
      ],
      messages: {
        unlocked: [buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED)],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(ADD_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.energyPlusTwo,
      },
    },
    {
      serviceIds: [
        PRODUCT_ENERGY,
        PRODUCT_MOBILE,
        PRODUCT_MOBILE_2,
        PRODUCT_INSURANCE,
      ],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
          buildUnlockedSaving(EXTRA_50_UNLOCKED),
        ],
        locked: [],
        savingsTip: savingsTips.allSelected,
      },
    },
    {
      serviceIds: [
        PRODUCT_ENERGY,
        PRODUCT_BROADBAND,
        PRODUCT_MOBILE,
        PRODUCT_MOBILE_2,
      ],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
          buildUnlockedSaving(EXTRA_50_UNLOCKED),
        ],
        locked: [],
        savingsTip: savingsTips.allSelected,
      },
    },
    {
      serviceIds: [
        PRODUCT_ENERGY,
        PRODUCT_BROADBAND,
        PRODUCT_MOBILE,
        PRODUCT_INSURANCE,
      ],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
          buildUnlockedSaving(EXTRA_50_UNLOCKED),
        ],
        locked: [],
        savingsTip: savingsTips.allSelected,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_BROADBAND, PRODUCT_MOBILE],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
        ],
        locked: [buildLockedSaving(ADD_ONE_EXTRA_50)],
        savingsTip: savingsTips.energyPlusTwo,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_BROADBAND, PRODUCT_INSURANCE],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
        ],
        locked: [buildLockedSaving(ADD_ONE_EXTRA_50)],
        savingsTip: savingsTips.energyPlusTwo,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_MOBILE, PRODUCT_INSURANCE],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
        ],
        locked: [buildLockedSaving(ADD_ONE_EXTRA_50)],
        savingsTip: savingsTips.energyPlusTwo,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_MOBILE, PRODUCT_MOBILE_2],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
        ],
        locked: [buildLockedSaving(ADD_ONE_EXTRA_50)],
        savingsTip: savingsTips.energyPlusTwo,
      },
    },
    {
      serviceIds: [PRODUCT_MOBILE, PRODUCT_MOBILE_2, PRODUCT_INSURANCE],
      messages: {
        unlocked: [
          buildUnlockedSaving(FIXED_ENERGY_OR_50_UNLOCKED),
          buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED),
        ],
        locked: [buildLockedSaving(ADD_ONE_EXTRA_50)],
        savingsTip: savingsTips.energyPlusTwo,
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_MOBILE, PRODUCT_INSURANCE],
      messages: {
        unlocked: [buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED)],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(ADD_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.multipleServices,
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_MOBILE, PRODUCT_MOBILE_2],
      messages: {
        unlocked: [buildUnlockedSaving(EARLY_TERMINATION_UNLOCKED)],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(ADD_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.multipleServices,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_BROADBAND],
      messages: {
        unlocked: [buildUnlockedSaving(FIXED_ENERGY_OR_20_UNLOCKED)],
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ONE_FIXED_OR_50,
              [],
              true,
              true
            )
          ),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_TWO_EXTRA_50),
        ],
        savingsTip: savingsTips.energyPlusOne,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_MOBILE],
      messages: {
        unlocked: [buildUnlockedSaving(FIXED_ENERGY_OR_20_UNLOCKED)],
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ONE_FIXED_OR_50,
              [],
              true,
              true
            )
          ),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_TWO_EXTRA_50),
        ],
        savingsTip: savingsTips.energyPlusOne,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_INSURANCE],
      messages: {
        unlocked: [buildUnlockedSaving(FIXED_ENERGY_OR_20_UNLOCKED)],
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ONE_FIXED_OR_50,
              [],
              true,
              true
            )
          ),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_TWO_EXTRA_50),
        ],
        savingsTip: savingsTips.energyPlusOne,
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_MOBILE],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_ONE_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.multipleServices,
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_INSURANCE],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_ONE_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.multipleServices,
      },
    },
    {
      serviceIds: [PRODUCT_MOBILE, PRODUCT_INSURANCE],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_ONE_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.multipleServices,
      },
    },
    {
      serviceIds: [PRODUCT_MOBILE, PRODUCT_MOBILE_2],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50),
          buildLockedSaving(EARLY_TERMINATION),
          buildLockedSaving(ADD_ONE_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.multipleServices,
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY],
      messages: {
        unlocked: [],
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ANOTHER_FIXED_OR_20
            )
          ),
          buildLockedSaving(ADD_TWO_FIXED_OR_50),
          buildLockedSaving(ADD_THREE_EXTRA_50),
        ],
        savingsTip: savingsTips.energyOnly,
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_20),
          buildLockedSaving(ANOTHER_AND_ENERGY_FIXED_OR_50),
          buildLockedSaving(ADD_TWO_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.oneService,
      },
    },
    {
      serviceIds: [PRODUCT_MOBILE],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_20),
          buildLockedSaving(ANOTHER_AND_ENERGY_FIXED_OR_50),
          buildLockedSaving(ADD_TWO_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.oneService,
      },
    },
    {
      serviceIds: [PRODUCT_INSURANCE],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_20),
          buildLockedSaving(ANOTHER_AND_ENERGY_FIXED_OR_50),
          buildLockedSaving(ADD_TWO_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.oneService,
      },
    },
    {
      serviceIds: [],
      messages: {
        unlocked: [],
        locked: [
          buildLockedSaving(BUNDLE_ONE_AND_ENERGY_FIXED_OR_20),
          buildLockedSaving(BUNDLE_TWO_AND_ENERGY_FIXED_OR_50),
          buildLockedSaving(BUNDLE_THREE_AND_ENERGY_EXTRA_50),
        ],
        savingsTip: savingsTips.noneSelected,
      },
    },
  ];
};
