import Immutable from 'immutable';

import {
  POST_SMART_METER_INTEREST_REQUEST,
  POST_SMART_METER_INTEREST_SUCCESS,
  POST_SMART_METER_INTEREST_FAILURE,
} from './constants';

const initialState = Immutable.fromJS({
  loading: false,
  submitted: false,
  error: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case POST_SMART_METER_INTEREST_REQUEST:
      return state.mergeDeep({ loading: true, error: null });
    case POST_SMART_METER_INTEREST_SUCCESS:
      return state.mergeDeep({ loading: false, submitted: true });
    case POST_SMART_METER_INTEREST_FAILURE:
      return state.mergeDeep({ loading: false, error: action.error });
    default:
      return state;
  }
}
