import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ThankYouPage from 'components/pages/ThankYou';
import * as actionCreators from 'redux/modules';
import { ApplicationApi } from '../App/api';

export default connect(
  (state) => ({ accountNumber: ApplicationApi.getAccountNumber(state) }),
  (dispatch) => bindActionCreators({ ...actionCreators.App }, dispatch)
)(ThankYouPage);
